* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #141414;
  color: #fff;
}

.adBlockAlert {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: saturate(180%) blur(10px);
  display: none;
}

.adBlockAlert.show {
  display: block;
}

.loudness-bar {
  width: 300px;
  height: 20px;
  background-color: #ccc;
  border: 1px solid #333;
  position: relative;
}

.loudness-fill {
  height: 100%;
  background-color: #3498db;
  width: 0;
  transition: width 0.2s ease-in-out;
}

canvas {
  width: 100vw;
  height: 100vh;
  transition: all 0.2s ease-in-out;
}

.colorTest {
  width: 50px;
  height: 50px;
  transition: background 0.2s ease-in-out;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.mediaBarContainer {
  display: flex;
  justify-content: center;
  padding: 1rem;
}

.mediaBar {
  width: 100%;
  max-width: 1500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem;
  position: relative;
  overflow: hidden;
  border-radius: 0.5rem;
  backdrop-filter: saturate(180%) blur(10px);
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.07);
  background: rgba(255, 255, 255, 0.01);
}

@media screen and (max-width: 400px) {
  .mediaBarContainer {
    padding: 0;
  }

  .mediaBar {
    border-radius: 0 0 0.5rem 0.5rem;
  }
}

.songInfo {
  display: flex;
  align-items: center;
}

.songInfo .coverContainer {
  background-color: rgba(255, 255, 255, 0.05);
  width: 3.4rem;
  height: 3.4rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  border-radius: 0.2rem;
  overflow: hidden;
}

.songInfo .cover {
  width: 100%;
  height: 100%;
}

.songInfo>.words {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
}

.songInfo>.words>.primary,
.songInfo>.words>.secondary {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.songInfo>.words p {
  margin: 0;
  white-space: nowrap;
}

.songInfo>.words>.primary {
  font-weight: 700;
  font-size: 1rem;
}

.songInfo>.words>.primary>.explicit {
  color: rgba(255, 255, 255, 0.75);
  margin-top: 0.12rem;
  margin-left: 0.5rem;
  font-size: 0.75rem;
}

.songInfo>.words>.secondary {
  opacity: 0.5;
  font-size: 0.8rem;
  margin-top: 0.15rem;
}

.songInfo>.words>.secondary>.separator {
  padding: 0 0.3rem;
}

.songInfo>.coverBlurred {
  position: absolute;
  z-index: -1;
  filter: blur(100px);
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  width: 100%;
  height: 100%;
}

.songInfo>.coverBlurred.loadingImage.loaded {
  opacity: 0.15;
}




@import url('https://fonts.googleapis.com/css2?family=Rowdies:wght@300;700&display=swap');

.logo {
  display: flex;
  justify-content: center;
  color: white;
  opacity: 0.25;
  font-size: 0.85rem;
  align-items: flex-end;
  font-weight: 300;
  font-family: "Rowdies";
  position: fixed;
  bottom: 0;
  width: 100vw;
  left: 0;
  padding-bottom: 1rem;
}

.logo-top {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.35rem;
  display: inline-block;
  margin-bottom: -.27rem;
  margin-right: .54rem;
}

.logo-bottom>svg {
  height: 1.35rem;
  margin-bottom: -0.373rem;
  margin-left: 0.325rem;
  overflow: visible;
}

.loadingImage {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.loadingImage.loaded {
  opacity: 1;
}

.loginContainer {
  backdrop-filter: saturate(50%) blur(12px);
  background: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  padding: 3rem 1rem;
}

@media screen and (max-height: 460px) {
  .loginContainer {
    align-items: flex-start;
  }
}

.login {
  max-width: 750px;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login h1,
.login h2 {
  font-family: "Rowdies";
  text-shadow: 0 0 1.5rem rgba(0, 0, 0, 1);
}

.login h1 {
  text-transform: uppercase;
  font-size: 5rem;
  margin: 0;
  font-weight: 700;
  white-space: nowrap;
}

.login h2 {
  font-weight: 300;
  display: flex;
  font-size: 1.7rem;
  margin: 0.2rem 0 10rem 0;
  align-self: flex-end;
  color: rgba(255, 255, 255, 0.6);
}

.login svg {
  height: 2.5rem;
  overflow: visible;
  margin-left: 1.2rem;
  transform: translateY(-0.1rem);
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 1));
}

@media screen and (max-width: 600px) {
  .login h1 {
    font-size: 4rem;
  }

  .login h2 {
    font-size: 1.5rem;
    margin-top: 0.33rem;
  }

  .login svg {
    height: 2.3rem;
    margin-left: 1.13rem;
    transform: translateY(-0.2rem);
  }
}

@media screen and (max-width: 500px) {
  .login h1 {
    font-size: 2.9rem;
  }

  .login h2 {
    font-size: 1.3rem;
    margin-top: 0.55rem;
  }

  .login svg {
    height: 2.1rem;
    margin-left: 1rem;
    transform: translateY(-0.25rem);
  }
}

.login svg path {
  fill: #1DB954 !important;
}

.login .connectButton {
  background-color: #1DB954;
  color: white;
  border-radius: 50rem;
  padding: 0.8rem 2rem;
  text-decoration: none;
  text-align: center;
  box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.5);
}

.login .connectButton:hover {
  font-weight: 700;
  opacity: 0.8;
  transition: all 0.125s ease-in-out;
}

.login .fine {
  opacity: 0.5;
  margin: 0.5rem 0 0.75rem 0;
  font-size: 0.8rem;
  font-style: italic;
}